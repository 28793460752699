<template>
  <div class="fake_select_wrapper d-flex align-center">
    <span
      v-if="icon"
      class="material-icons input_icon"
      @click="(showSortBy = !showSortBy), (sort_by_arrow = !sort_by_arrow)"
    >
      {{ icon }}
    </span>
    <div class="fake_select">
      <input
        type="text"
        class="text_input"
        id="sortBy"
        name="sortBy"
        :value="value"
        @click="(showSortBy = !showSortBy), (sort_by_arrow = !sort_by_arrow)"
        readonly
        :disabled="disabled"
      />
      <div v-if="showSortBy && !disabled" class="drop_options_check">
        <div class="drop_option_check d-flex">
          <label class="custom-checkbox-2">
            <input
              type="checkbox"
              v-model="allSort"
              id="All"
              @change="check($event)"
            />
            <span class="checkmark-2"></span>
          </label>
          All
        </div>
        <div v-for="(sortKey, index) in newSortList" :key="index">
          <div class="drop_option_check d-flex">
            <label class="custom-checkbox-2">
              <input
                type="checkbox"
                v-model="sortKey.selected"
                @change="$emit('selected', { selected: newSortList })"
              />
              <span class="checkmark-2"></span>
            </label>
            {{ sortKey.name }}
          </div>
        </div>
      </div>
    </div>

    <span
      class="material-icons icon"
      @click="(showSortBy = !showSortBy), (sort_by_arrow = !sort_by_arrow)"
      :class="sort_by_arrow ? 'arrow_active' : ''"
    >
      expand_more
    </span>
  </div>
</template>

<script>
export default {
  props: {
    sortList: { type: Array || null, default: [], required: true },
    icon: { type: String, required: false },
    disabled: { type: Boolean, default: false, required: false },
    value: { type: String, required: true },
  },
  data() {
    return {
      sortBy: "",
      showSortBy: false,
      sort_by_arrow: false,
      newSortList: [],
      allSort: false,
    };
  },
  created() {
    if (!this.sortList.length) return;
    this.sortBy = this.sortList[0];
  },
  mounted() {
    this.newSortList = JSON.parse(JSON.stringify(this.sortList));
    const found = this.newSortList.find(
      (sortItem) => sortItem.selected === false
    );
    this.allSort = found ? false : true;
  },
  methods: {
    check(e) {
      
      if (this.newSortList.length === 0) return;
      if (e.target.checked) {
        this.newSortList.forEach((sortItem) => {
          sortItem.selected = true;
        });
      } else {
        this.newSortList.forEach((sortItem) => {
          sortItem.selected = false;
        });
      }

      this.$emit("selected", { selected: this.newSortList });
    },
  },
};
</script>

<style lang="scss" scoped>
#sortBy {
  width: 362px;
}
.arrow_active {
  transform: rotateZ(180deg);
  transition: 0.2s;
}
</style>
