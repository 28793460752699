<template>
  <div
    :style="{
      backgroundColor: !$route.path.includes('sh-retailer')
        ? '#f6f6f7'
        : '#fff',
    }"
    class="import_list_card"
    :class="
      pushingToStore || removingProduct ? 'import_list_card_disabled' : ''
    "
  >
    <div class="product-detail-header-navigation justify-space-between">
      <div class="product-detail-container d-flex align-center">
        <BaseBtn
          @clicked="close()"
          style="border: none; padding: 0; margin-right: 10px"
          ><img
            class="avatar"
            src="@/assets/img/back-button.png"
            alt="view"
            style="width: 44px"
        /></BaseBtn>
        <div class="product-detail-info">
          <h2>Product detail</h2>
          <h6 v-if="product.title">{{ newProduct.title }}</h6>
        </div>
      </div>
      <div
        class="left d-flex buttons-wrapper justify-space-between flex-wrap align-center gap-5"
      >
        <div class="d-flex align-stretch justify-center second_child">
          <BaseBtn
            v-if="!disablePushToStore"
            :disabled="disablePushToStore"
            class="btn_green"
            :class="{
              active: !disablePushToStore,
              outlined: disablePushToStore,
            }"
            @clicked="pushToStore(newProduct.id)"
          >
            Push to store
          </BaseBtn>
          <BaseBtn
            v-else
            class="btn_green"
            :class="{
              active: !disablePushToStore,
              outlined: disablePushToStore,
            }"
            @clicked="close()"
          >
            Back to import list
          </BaseBtn>
          <BaseBtn
            v-if="!isEditingProduct"
            class="mx-3"
            :class="{
              disabled: disableSaveChange,
              btn_green: !disableSaveChange,
              outlined: disableSaveChange,
            }"
            :disabled="disableSaveChange"
            style="
              background: #fff !important;
              color: #333;
              border: 1px solid #333;
            "
            @clicked="!disableSaveChange && saveChange()"
          >
            Save Changes
          </BaseBtn>
          <button
            v-else
            style="
              background: #fff !important;
              color: #333;
              border: 1px solid #333;
              font-size: 14px;
              padding: 5px 20px;
              border-radius: 5px;
              height: 45px;
            "
            class="submit-btn"
            id="product-addtocart-button"
            title="Add to Bag"
            type="button"
          >
            <span id="loading">
              <span>•</span>
              <span>•</span>
              <span>•</span>
            </span>
          </button>

          <nav aria-label="Pagination" class="Polaris-Pagination">
            <div
              class="Polaris-ButtonGroup Polaris-ButtonGroup--segmented"
              data-buttongroup-segmented="true"
            >
              <div class="Polaris-ButtonGroup__Item">
                <button
                  :style="{
                    background:
                      counterOfIndex === 0 ? 'rgb(246 246 246)' : '#fff',
                  }"
                  :class="{ disabled: counterOfIndex == 0 }"
                  :disabled="counterOfIndex == 0"
                  @click="$emit('previous-edit-product')"
                  style="padding: 12px"
                  id="previousURL"
                  class="Polaris-Button previousURL Polaris-Button--outline Polaris-Button--iconOnly"
                  aria-label="Previous"
                  type="button"
                >
                  <span class="Polaris-Button__Content">
                    <span class="Polaris-Button__Icon">
                      <span class="Polaris-Icon">
                        <span
                          class="Polaris-Text--root Polaris-Text--visuallyHidden"
                        >
                        </span>
                        <svg
                          viewBox="0 0 20 20"
                          class="Polaris-Icon__Svg"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M11.78 5.47a.75.75 0 0 1 0 1.06l-3.47 3.47 3.47 3.47a.75.75 0 1 1-1.06 1.06l-4-4a.75.75 0 0 1 0-1.06l4-4a.75.75 0 0 1 1.06 0Z"
                          ></path>
                        </svg>
                      </span>
                    </span>
                  </span>
                </button>
              </div>

              <div class="Polaris-ButtonGroup__Item">
                <button
                  :style="{
                    background:
                      counterOfIndex === countOfProduct - 1
                        ? 'rgb(246 246 246)'
                        : '#fff',
                  }"
                  :class="{ disabled: counterOfIndex === countOfProduct - 1 }"
                  :disabled="counterOfIndex === countOfProduct - 1"
                  @click="$emit('next-edit-product')"
                  style="padding: 12px"
                  id="nextURL"
                  class="Polaris-Button nextURL Polaris-Button--outline Polaris-Button--iconOnly"
                  aria-label="Next"
                  type="button"
                >
                  <span class="Polaris-Button__Content">
                    <span class="Polaris-Button__Icon">
                      <span class="Polaris-Icon">
                        <span
                          class="Polaris-Text--root Polaris-Text--visuallyHidden"
                        >
                        </span>
                        <svg
                          viewBox="0 0 20 20"
                          class="Polaris-Icon__Svg"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M7.72 14.53a.75.75 0 0 1 0-1.06l3.47-3.47-3.47-3.47a.75.75 0 0 1 1.06-1.06l4 4a.75.75 0 0 1 0 1.06l-4 4a.75.75 0 0 1-1.06 0Z"
                          ></path>
                        </svg>
                      </span>
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <div class="import_list_body" style="min-height: auto !important">
      <span class="card_title">Product Info</span>

      <div class="form_container" style="width: 100%; max-width: 100%">
        <CustomInputV2
          :isSavedProduct="isSavedProduct"
          type="text"
          :id="newProduct.id"
          label="Product Name"
          v-model="newProduct.title"
          :placeholder="newProduct.title"
        />
        <div class="input_container" style="width: 100%">
          <div class="d-flex">
            <span class="input_label">Product tags</span>
            <span
              class="input_label ml-auto"
              style="font-weight: 400; border-radius: 5px; font-size: 14px"
              id="removeAllTags"
              @click="removeAllTags"
              >Remove all tags</span
            >
          </div>

          <v-combobox
            class="combobox_custom_input combobox-2"
            :style="{
              background: isSavedProduct ? '#FAFBFB' : '#fff',
            }"
            style="
              height: auto;
              padding: 5px !important;
              min-height: auto !important;
            "
            v-model="newProduct.tags"
            multiple
            flat
            hide-details
            solo
            append-icon=""
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                :style="{
                  'pointer-events': isSavedProduct ? 'none' : 'auto',
                  color: isSavedProduct ? '#8C9196' : 'auto',
                  background: isSavedProduct ? '#FAFBFB' : '#fff',
                }"
                v-bind="attrs"
                :input-value="selected"
                ripple
                color="#F5F6F7"
                @click="select"
                @click:close="remove(item)"
              >
                <p class="ma-0">{{ item }}</p>
                <span
                  style="font-size: 14px"
                  class="material-symbols-outlined ml-3"
                  @click="remove(item)"
                >
                  close
                </span>
              </v-chip>
            </template>
          </v-combobox>
        </div>
      </div>
    </div>
    <div class="import_list_body">
      <span class="card_title">Product Info</span>

      <div class="import_list_text_editor">
        <ckeditor
          v-model="newProduct.description"
          :config="editorConfig"
          @input="onEditorInput"
          class="ckEditor"
        ></ckeditor>
      </div>
    </div>
    <div class="import_list_body" style="overflow: scroll">
      <div class="header-container d-flex justify-space-between w-100">
        <div class="product-variations-container">
          <span class="card_title">Product variations</span>
          <v-menu
            content-class="bulk-menu"
            offset-y
            :close-on-content-click="false"
            transition="scale-transition"
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <th
                class="th_icon bulk"
                @click="sale_price_arrow = !sale_price_arrow"
                :class="sale_price_arrow ? 'arrow_active' : ''"
                v-bind="attrs"
                v-on="on"
              >
                Bulk edit
                <span class="material-icons icon ml-1"> expand_more </span>
              </th>
            </template>
            <div class="menu_container">
              <div class="search_container suffix d-flex align-center pa-2">
                <input
                  class="imbaded_input mr-1"
                  type="number"
                  name="sale_price_profit"
                  id="sale_price_profit"
                  placeholder="ex. 40%"
                  min="0"
                  max="100"
                  v-model.number="sale_price_profit"
                />
                <button class="imbaded_button ml-auto" @click="SetNewProfit()">
                  Apply
                </button>
              </div>
              <span @click="restToDefault()" class="bulk">
                Reset to default
              </span>
            </div>
          </v-menu>
        </div>

        <v-menu
          content-class="shipping-menu"
          offset-y
          :close-on-content-click="false"
          transition="scale-transition"
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <th
              class="th_icon shipping-drop-down"
              @click="shipping_arrow = !shipping_arrow"
              :class="shipping_arrow ? 'arrow_active' : ''"
              v-bind="attrs"
              v-on="on"
            >
              Ship to:
              <span class="ml-2" style="font-size: 14px; font-weight: 700">{{
                newProduct.shippings[SelectedShippingIndex].country
              }}</span>
              <span class="material-icons icon ml-1 ml-auto">
                expand_more
              </span>
            </th>
          </template>
          <div class="menu_container">
            <div class="d-flex-column">
              <div
                v-for="(shipping, indexShipping) in newProduct.shippings"
                :key="indexShipping"
                @click="SelectedShippingIndex = indexShipping"
                class="countries clickable py-3 d-flex align-center"
              >
                <img
                  loading="lazy"
                  :src="
                    require(`@/assets/img/flags/${shipping.countryISO2}.svg`)
                  "
                  :alt="`${shipping.countryISO2}`"
                  class="flag"
                />
                <span class="country">{{ shipping.countryISO2 }}</span>
              </div>

              <div class="countries clickable py-3 d-flex align-center">
                <v-dialog v-model="shipping_detail_dialog" width="480px">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <img
                        loading="lazy"
                        src="@/assets/img/flags/WW.svg"
                        alt="Flag"
                        class="flag"
                      />

                      <span class="country">Show shipping price</span>
                    </div>
                  </template>

                  <div
                    class="shipping_detail_wrapper d-flex flex-column align-center justify-center pa-6"
                  >
                    <div class="align-self-end">
                      <span
                        style="font-size: 14px"
                        class="material-symbols-outlined grey-500 ml-3"
                        @click="shipping_detail_dialog = false"
                      >
                        close
                      </span>
                    </div>
                    <div class="align-self-start">
                      <h2 class="mb-6">Shipping Details</h2>
                      <!-- <h2 class="mb-6">Shipping from Spain</h2> -->
                    </div>

                    <div>
                      <table
                        class="import-list-table shipping_table"
                        style="min-width: 432px"
                      >
                        <thead class="theader">
                          <tr>
                            <th>Destination</th>
                            <th>Cost</th>
                            <th>Delivary days</th>
                          </tr>
                        </thead>
                        <tbody class="tbody">
                          <tr
                            class="each-row"
                            v-for="(
                              shipping, indexShipping
                            ) in newProduct.shippings"
                            :key="indexShipping"
                          >
                            <td>{{ shipping.country }}</td>

                            <td
                              v-if="
                                shipping.min_price &&
                                shipping.max_price &&
                                shipping.min_price !== shipping.max_price
                              "
                            >
                              {{ toCurrency(shipping.min_price) }}
                              -
                              {{ toCurrency(shipping.max_price) }}
                            </td>
                            <td
                              v-else-if="
                                shipping.min_price &&
                                (!shipping.max_price ||
                                  shipping.min_price === shipping.max_price)
                              "
                            >
                              {{ toCurrency(shipping.min_price) }}
                            </td>
                            <td v-else>Free</td>
                            <td>
                              {{ shipping.min_delivery_date }} -
                              {{ shipping.max_delivery_date }}
                              days
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="shipping_detail_note">
                      <p>
                        Does not ship to: Algeria, American Samoa, Argentina,
                        Azerbaijan, Bahrain, Bangladesh, Benin, Bermuda, Bosnia
                        And Herzegovina, Brazil, Brunei Darussalam, Congo, Costa
                        Rica, Cyprus, Dominican Republic, Ecuador, Egypt, El
                        Salvador, French Guiana, Greenland, Guadeloupe, Guam,
                        Guatemala, Honduras, Hong Kong, India, Iraq, Isle of
                        Man, Italy, Jordan, Kazakhstan, Kuwait, Lebanon,
                        Macedonia, Madagascar, Martinique, Mauritius, Moldova,
                        Republic of, Montserrat, Morocco, North Macedonia, Oman,
                        Pakistan, Panama, Papua New Guinea, Peru, Philippines,
                        Puerto Rico, Qatar, Reunion Island, Romania, Serbia,
                        South Africa, Sri Lanka, Syria, Taiwan, Tunisia, Uganda,
                        Venezuela, and Vietnam
                      </p>
                    </div>
                  </div>
                </v-dialog>
              </div>
            </div>
          </div>
        </v-menu>
      </div>

      <table class="import-list-table">
        <thead class="theader">
          <tr>
            <th>
              <label class="custom-checkbox-2">
                <input
                  type="checkbox"
                  :id="newProduct.id"
                  v-model="allVariants"
                  @change="check($event)"
                />
                <span class="checkmark-2 table-checkmark"></span>
                image
              </label>
            </th>
            <th v-for="(optionName, index) in AvailableOptions" :key="index">
              {{ optionName }}
            </th>
            <th>Inventory</th>
            <th>Selling price</th>
            <v-menu
              offset-y
              :close-on-content-click="false"
              transition="scale-transition"
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <th class="th_icon">Shipping price</th>
              </template>
              <div class="menu_container">
                <div class="d-flex-column">
                  <div
                    v-for="(shipping, indexShipping) in newProduct.shippings"
                    :key="indexShipping"
                    @click="SelectedShippingIndex = indexShipping"
                    class="countries clickable py-3 d-flex align-center"
                  >
                    <img
                      loading="lazy"
                      :src="
                        require(`@/assets/img/flags/${shipping.countryISO2}.svg`)
                      "
                      :alt="`${shipping.countryISO2}`"
                      class="flag"
                    />
                    <span class="country">{{ shipping.countryISO2 }}</span>
                  </div>

                  <div class="countries clickable py-3 d-flex align-center">
                    <v-dialog v-model="shipping_detail_dialog" width="480px">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <img
                            src="@/assets/img/flags/WW.svg"
                            alt="Flag"
                            class="flag"
                            loading="lazy"
                          />

                          <span class="country">Show shipping price</span>
                        </div>
                      </template>

                      <div
                        class="shipping_detail_wrapper d-flex flex-column align-center justify-center pa-6"
                      >
                        <div class="align-self-end">
                          <span
                            style="font-size: 14px"
                            class="material-symbols-outlined grey-500 ml-3"
                            @click="shipping_detail_dialog = false"
                          >
                            close
                          </span>
                        </div>
                        <div class="align-self-start">
                          <h2 class="mb-6">Shipping Details</h2>
                        </div>

                        <div>
                          <table
                            class="import-list-table shipping_table"
                            style="min-width:432px:"
                          >
                            <thead class="theader">
                              <tr>
                                <th>Destination</th>
                                <th>Cost</th>
                                <th>Delivary days</th>
                              </tr>
                            </thead>
                            <tbody class="tbody">
                              <tr
                                class="each-row"
                                v-for="(
                                  shipping, indexShipping
                                ) in newProduct.shippings"
                                :key="indexShipping"
                              >
                                <td>{{ shipping.country }}</td>

                                <td
                                  v-if="
                                    shipping.min_price &&
                                    shipping.max_price &&
                                    shipping.min_price !== shipping.max_price
                                  "
                                >
                                  {{ toCurrency(shipping.min_price) }}
                                  -
                                  {{ toCurrency(shipping.max_price) }}
                                </td>
                                <td
                                  v-else-if="
                                    shipping.min_price &&
                                    (!shipping.max_price ||
                                      shipping.min_price === shipping.max_price)
                                  "
                                >
                                  {{ toCurrency(shipping.min_price) }}
                                </td>
                                <td v-else>Free</td>
                                <td>
                                  {{ shipping.min_delivery_date }} -
                                  {{ shipping.max_delivery_date }}
                                  days
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="shipping_detail_note">
                          <p>
                            Does not ship to: Algeria, American Samoa,
                            Argentina, Azerbaijan, Bahrain, Bangladesh, Benin,
                            Bermuda, Bosnia And Herzegovina, Brazil, Brunei
                            Darussalam, Congo, Costa Rica, Cyprus, Dominican
                            Republic, Ecuador, Egypt, El Salvador, French
                            Guiana, Greenland, Guadeloupe, Guam, Guatemala,
                            Honduras, Hong Kong, India, Iraq, Isle of Man,
                            Italy, Jordan, Kazakhstan, Kuwait, Lebanon,
                            Macedonia, Madagascar, Martinique, Mauritius,
                            Moldova, Republic of, Montserrat, Morocco, North
                            Macedonia, Oman, Pakistan, Panama, Papua New Guinea,
                            Peru, Philippines, Puerto Rico, Qatar, Reunion
                            Island, Romania, Serbia, South Africa, Sri Lanka,
                            Syria, Taiwan, Tunisia, Uganda, Venezuela, and
                            Vietnam
                          </p>
                        </div>
                      </div>
                    </v-dialog>
                  </div>
                </div>
              </div>
            </v-menu>
            <v-menu
              offset-y
              :close-on-content-click="false"
              transition="scale-transition"
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <th class="th_icon">Market Price</th>
              </template>
              <div class="menu_container">
                <div class="search_container suffix d-flex align-center pa-2">
                  <input
                    class="imbaded_input mr-1"
                    type="number"
                    name="sale_price_profit"
                    id="sale_price_profit"
                    placeholder="ex. 40%"
                    min="0"
                    max="100"
                    v-model.number="sale_price_profit"
                  />
                  <button
                    class="imbaded_button ml-auto"
                    @click="SetNewProfit()"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </v-menu>
          </tr>
        </thead>
        <tbody class="tbody">
          <tr
            v-for="variant in newProduct.variants"
            :key="variant.id"
            class="each-row"
            style="border: 1px solid #e7eaee"
          >
            <td>
              <div class="first-section">
                <label class="custom-checkbox-2">
                  <input
                    type="checkbox"
                    :id="variant.id"
                    v-model="variant.selected"
                  />
                  <span class="checkmark-2 table-checkmark"></span>
                </label>
                <img
                  loading="lazy"
                  :src="product?.images[0]?.ThumbnailImage"
                  style="width: 50px; height: 50px; border-radius: 5px"
                />
              </div>
            </td>
            <td
              v-for="(optionName, optionNameIndex) in AvailableOptions"
              :key="optionNameIndex"
            >
              <span> {{ OptionValue(variant, optionName) }} </span>
            </td>
            <td class="">
              {{ variant.stock }}
              <span
                v-if="variant.stock <= 5 && variant.stock >= 1"
                class="stock_alert ml-6"
              >
                Low stock
              </span>
              <span v-else-if="variant.stock == 0" class="stock_low ml-6">
                Out of stock
              </span>
            </td>
            <td>{{ toCurrency(variant.price) }}</td>
            <td
              v-if="
                newProduct.shippings[SelectedShippingIndex].min_price &&
                newProduct.shippings[SelectedShippingIndex].max_price &&
                newProduct.shippings[SelectedShippingIndex].min_price !==
                  newProduct.shippings[SelectedShippingIndex].max_price
              "
            >
              {{
                toCurrency(
                  newProduct.shippings[SelectedShippingIndex].min_price
                )
              }}
              -
              {{
                toCurrency(
                  newProduct.shippings[SelectedShippingIndex].max_price
                )
              }}
            </td>
            <td
              v-else-if="
                newProduct.shippings[SelectedShippingIndex].min_price &&
                (!newProduct.shippings[SelectedShippingIndex].max_price ||
                  newProduct.shippings[SelectedShippingIndex].min_price ===
                    newProduct.shippings[SelectedShippingIndex].max_price)
              "
            >
              {{
                toCurrency(
                  newProduct.shippings[SelectedShippingIndex].min_price
                )
              }}
            </td>
            <td v-else>Free</td>
            <td>
              <input
                type="number"
                id="sales_price"
                name="sales_price"
                min="0"
                v-model.number="variant.sales_price"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="import_list_body">
      <span class="card_title">Product Images</span>

      <div class="images-wrapper" style="width: 100%">
        <div
          class="each"
          v-for="image in newProduct.images"
          :key="image.ID"
          :class="image.Selected ? 'selected' : ''"
        >
          <img
            src="@/assets/img/tick-small_minor.png"
            :class="image.Selected ? 'selected' : ''"
            alt="!"
            class="check"
            loading="lazy"
          />
          <img
            :src="`${image.ThumbnailImage}`"
            alt="product_image"
            class="image"
            @click="mediaSelect(image.ID)"
            loading="lazy"
          />
        </div>
      </div>
    </div>
    <div class="import_list_footer"></div>
  </div>
</template>

<script>
import CustomInputV2 from "@/components/CustomInputV2.vue";
import SelectDropV2 from "@/components/bases/SelectDropV2.vue";
import { mapGetters, mapActions } from "vuex";
import SelectDrop from "@/components/bases/SelectDrop.vue";
import MultiSelectDrop from "@/components/bases/MultiSelectDrop.vue";
import BaseBtn from "@/components/bases/BaseBtn.vue";
import RetailerProducts from "@/services/RetailerProducts";
export default {
  components: {
    CustomInputV2,
    SelectDrop,
    MultiSelectDrop,
    BaseBtn,
    SelectDropV2,
  },
  name: "ImportListCard",

  props: {
    counterOfIndex: { type: Number, required: false },
    countOfProduct: { type: Number, required: false },
    product: { type: Object, required: true },
    isPushedToStore: { type: Boolean, required: false },
  },
  data: () => ({
    selectedProduct: false,
    newProduct: {},
    isSavedProduct: false,
    cardStep: "product",

    disablePushToStore: Boolean,
    disableSaveChange: Boolean,
    ErrorMessage: false,
    SuccessMessage: false,
    editorConfig: {
      //  editorplaceholder : 'Type your comment…'
    },
    isEditingProduct: false,
    SelectedShippingIndex: 0,
    productVarient: [],
    allVariants: false,
    showCategories: false,
    sale_price_profit: Number,
    sale_price_arrow: false,
    shipping_arrow: false,
    collection_arrow: false,
    categories_arrow: false,
    shipping_detail_dialog: false,
    pushingToStore: false,
    removingProduct: false,

    defaultVariants: [],
  }),
  filters: {
    currency: function (value) {
      return "€ " + value;
    },
  },
  created() {
    this.newProduct = JSON.parse(JSON.stringify(this.product));
    this.defaultVariants = JSON.parse(JSON.stringify(this.product.variants));

    this.disableSaveChange = true;
    this.disablePushToStore = false;
    const variants = this.newProduct.variants;
    const found = variants.find((variant) => variant.selected === false);
    this.allVariants = found ? false : true;
  },

  computed: {
    ...mapGetters({
      newAlert: "alert/newAlert",
      alertSuccess: "alert/alertSuccess",
      alertError: "alert/alertError",
    }),
    ProductTitle() {
      return this.newProduct.title;
    },
    AvailableOptions() {
      const availableOptions = [
        ...new Set(
          this.newProduct.variants.map((variant) => variant.options).flat()
        ),
      ];
      const UniqueOptionNames = [
        ...new Set(
          availableOptions.map((option) => {
            return option.name;
          })
        ),
      ];
      return UniqueOptionNames;
    },
    Currency() {
      var formatter = new Intl.NumberFormat("en-EU", {
        style: "currency",
        currency:
          this.newProduct.shippings[this.SelectedShippingIndex].currency,
      });
      const currency = formatter.format(0);

      return currency[0];
    },
    defaultCurrency() {
      return this.newProduct.shippings[this.SelectedShippingIndex].currency;
    },
    // function that check if a variant as less than 10 in stock
    hasLowStock() {
      const found = this.newProduct.variants.find(
        (variant) => variant.stock <= 10
      );
      return found ? true : false;
    },
  },
  methods: {
    ...mapActions(["alert/clear", "alert/newAlert"]),
    backToImportList(productID) {
      RetailerProducts.backToImportList(productID)
        .then((response) => {
          // hcheck the reponse here before doing this next lines

          this.backingImportlist = false;
          this["alert/newAlert"]({
            type: "success",
            message: "Product backed to import list successfully",
          });
          setTimeout(async () => {
            this.showAlertError = false;
            this["alert/clear"]();
          }, 3000);
        })
        .catch((e) => {
          this["alert/newAlert"]({
            type: "error",
            message: e.data.message,
          });
          setTimeout(async () => {
            this.showAlertError = false;
            this["alert/clear"]();
          }, 3000);

          console.log(e);
        });
    },
    ...mapActions([
      "retailer/updateImportedList",
      "retailer/submitImportedList",
      "retailer/removeImportedList",

      "alert/clear",
    ]),

    randomColor() {
      var letters = "BCDEF".split("");
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * letters.length)];
      }
      return color;
    },
    close() {
      this.$emit("force-render");
      this.$emit("close");
    },

    toCurrency: function (price) {
      var formatter = new Intl.NumberFormat("en-EU", {
        style: "currency",
        currency: this.defaultCurrency,
      });
      const curerncy_price = formatter.format(price);

      return curerncy_price;
    },
    restToDefault() {
      this.newProduct.variants = JSON.parse(
        JSON.stringify(this.product.variants)
      );
    },
    SetNewProfit() {
      const variants = this.newProduct.variants;
      variants.forEach((variant) => {
        variant.sales_price = Math.round(
          (variant.sales_price * this.sale_price_profit) / 100 +
            variant.sales_price
        );
      });
    },

    changeCardStep(step) {
      this.cardStep = step;
    },
    mediaSelect(imageID) {
      // console.log("imageID", imageID);
      this.newProduct.images.forEach((image) => {
        if (image.ID === imageID) {
          image.Selected = !image.Selected;
        }
      });
    },
    onEditorInput() {
      // console.log(this.newProduct.description);
    },
    remove(item) {
      this.newProduct.tags.splice(this.newProduct.tags.indexOf(item), 1);
      this.newProduct.tags = [...this.newProduct.tags];
    },
    async saveChange() {
      this.isEditingProduct = true;
      this.disableSaveChange = true;
      const saved = await this["retailer/updateImportedList"](this.newProduct);

      if (saved) {
        this.isEditingProduct = false;
        this.SuccessMessage = true;
        this.disablePushToStore = false;
        this.isSavedProduct = true;
      } else {
        this.isEditingProduct = false;
        this.ErrorMessage = true;
        this.disableSaveChange = false;
      }
    },
    async pushToStore(productID) {
      this.pushingToStore = true;
      this.disablePushToStore = true;
      this.selectedProduct = false;
      const pushed = await this["retailer/submitImportedList"](productID);
      if (pushed) {
        this.SuccessMessage = true;
        this.disableSaveChange = true;
        setTimeout(() => {
          this.pushingToStore = false;
          this.removingProduct = false;
        }, 1000);
      } else {
        this.ErrorMessage = true;
        this.disablePushToStore = false;
        this.selectedProduct = true;

        setTimeout(() => {
          this.pushingToStore = false;
        }, 1000);
      }
    },

    check(e) {
      if (e.target.checked) {
        this.newProduct.variants.forEach((variant) => {
          variant.selected = true;
        });
      } else {
        this.newProduct.variants.forEach((variant) => {
          variant.selected = false;
        });
      }
    },

    removeAllTags() {
      this.newProduct.tags = [];
    },
    OptionValue(variant, optionName) {
      let val = " --- ";
      for (let i = 0; i < variant.options.length; i++) {
        const option = variant.options[i];
        if (option.name === optionName) {
          val = option.value;
          break;
        } else {
          continue;
        }
      }
      // console.log("val", val);
      return val;
    },

    SelecteCollection(event) {
      this.newProduct.coll = event.selected;
    },
  },
  watch: {
    newProduct: {
      handler(val) {
        if (JSON.stringify(val) !== JSON.stringify(this.product)) {
          this.disableSaveChange = false;
          //this.disablePushToStore = true;
        } else if (JSON.stringify(val) === JSON.stringify(this.product)) {
          this.disableSaveChange = true;
        }
        let found = JSON.parse(JSON.stringify(val)).variants.find(
          (variant) => variant.selected === false
        );
        this.allVariants = found ? false : true;
      },
      deep: true,
      flush: "post",
    },
    alertSuccess: {
      handler(val) {
        if (val) {
          setTimeout(() => {
            this["alert/clear"]();
            this.SuccessMessage = false;
          }, 1000);
        }
      },
    },
    alertError: {
      handler(val) {
        if (val) {
          setTimeout(() => {
            this["alert/clear"]();
            this.ErrorMessage = false;
          }, 1000);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables2.scss";

.btn_green {
  background: #008060 !important;
  color: #fff;
  font-size: 14px;
  padding: 5px 20px;
  border-radius: 5px;
  height: 45px;
  @include breakpoint(550px) {
    font-size: 12px;
    padding: 3px 14px;
    height: 40px;
  }
}
.Polaris-Pagination {
  @include breakpoint(550px) {
    margin-left: auto;
    justify-content: flex-end;
  }
  @include sm {
    justify-content: flex-start;
  }
}
.nextURL,
.previousURL {
  @include breakpoint(550px) {
    padding: 9px !important;
  }
}

.second_child,
.buttons-wrapper {
  @include md {
    width: 100%;
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }
}
.second_child {
  @include sm {
    display: grid !important;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    row-gap: 14px;
  }
}
.submit-btn {
  min-width: 134px;
  margin: 0 12px;
  padding: 12px 0;
  @include flex-center;
  background: #008f4a;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  color: #fff;
  font-size: 16px;

  transition: $transition;
  &:hover {
    background: #0bbb66;
    color: #fefefe;
  }
}
#loading {
  font-size: 20px;
  text-align: center;
}
#loading span:not(:last-child) {
  margin-right: 5px;
}
#loading span {
  -webkit-animation-name: opacity;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: opacity;
  -moz-animation-duration: 1s;
  -moz-animation-iteration-count: infinite;
}
#loading span:nth-child(2) {
  -webkit-animation-delay: 100ms;
  -moz-animation-delay: 100ms;
}
#loading span:not(:last-child) {
  margin-right: 5px;
}
#loading span:nth-child(3) {
  -webkit-animation-delay: 300ms;
  -moz-animation-delay: 300ms;
}
@keyframes opacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes opacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.product-variations-container {
  position: relative;
}
.shipping-menu {
  background: #fff;
}
.bulk-menu {
  transform: translateY(10px);
}
.shipping-drop-down {
  width: 250px;
  border: 1px solid #aeb4b9;
  display: flex;
  align-items: center;
  height: 40px;

  border-radius: 5px;
  padding: 0 10px;
  font-size: 13px;
  color: #6d7175;
  font-weight: 600;
  .icon {
    position: relative !important;
  }
}

.bulk {
  display: block;
  color: #2c6ecb !important;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  margin-top: 8px;
}

.product-detail-header {
  padding: 40px 10px;
  margin-top: 30px;
  border-bottom: 1px solid #e1e3e5;

  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15),
    0px 0px 5px 0px rgba(0, 0, 0, 0.05);
}

.product-detail-header-navigation {
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #e1e3e5;
  margin-bottom: 20px;
  @include lg {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 14px;
  }
}

.product-detail-header-navigation a {
  margin-right: 15px;
}

.product-detail-info {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.product-detail-info h2 {
  font-weight: bold;
  font-size: 16px;
}

.product-detail-info h6 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #6d7175;
}

.card_title {
  font-size: 16px;
  font-weight: bold;
}
.import_list_card {
  position: absolute;
  top: -24px;
  left: 0;
  padding: 32px !important;
  z-index: 9;
  bottom: 0;
  height: 100vh;
  background: #fff;
  width: 100%;
  border-radius: 0;
  margin: 0;
  @include md {
    padding: 12px !important;
  }
}
.import_list_body {
  background: #fff;
  margin-bottom: 20px;
  border-radius: 10px;

  box-shadow: 0px 0px 5px 0px #0000000d;
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) {
  padding: 16px 0 !important;
}
.v-chip__content > strong {
  font-size: 14px;
  font-weight: normal;
  color: $grey-500;
}
.mdi-close::before {
  content: "\F0156";
  font-size: 16px;
  font-weight: 600;
}
.theme--light.v-chip:not(.v-chip--active) {
  padding: 20px;
  border-radius: 8px;
  background-color: red;
}
.theme--light.v-chip.v-chip--select {
  background-color: $grey-50 !important;
  padding: 16px;
  border-radius: 4px;
}
.theme--light.v-chip.v-chip--select.v-chip--active {
  background-color: $grey-50 !important;
}
.body-section {
  height: 345.078px;
  max-height: 445.078px;

  overflow-y: scroll;
  overflow-x: scroll;
  display: flex;
  flex-direction: column-reverse;
}
.theader th {
  background: #e1e3e5 !important ;
  color: #6d7175;
}
.tbody tr:nth-child(even) {
  background: #f5f6f7 !important ;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* hide input number arrows */
/* Firefox */
.suffix::after {
  content: "%";
  position: absolute;
  right: 114px;
  top: 16px;
  font-size: $font_size_lg + 0.1rem;
  color: $grey-500;
  font-weight: bold;
}
input[type="number"] {
  -moz-appearance: textfield;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.step:hover {
  background-color: $grey-100;
}
.each {
  &.selected {
    // background-color: $primary !important;
    border: 3px solid #f2f7fe !important;
  }
}
.checkbox_disabled {
  cursor: not-allowed;
}
.combobox-2 {
  // min-height: 92px !important;
  overflow-y: scroll;
}
#removeAllTags {
  cursor: pointer;
  color: $primary;
}
#categories {
  width: 362px;
}
#collections {
  width: 362px;
}
#sales_price {
  border: solid 1px #aeb4b9;
  border-radius: 5px;
  padding: 12px;

  &:focus-visible {
    outline: none;
    border: solid 2px $primary;
  }
}
.arrow_active {
  .icon {
    transition: 0.2s;
    transform: rotateZ(180deg);
    transition: 0.2s;
  }
}
.menu_container {
  background: white;
  padding: 16px 8px;
  display: inline-block;
  .search_container {
    border-radius: 10px;
    border: solid 1px $grey-400;
    padding: 5px;
    position: relative;
    width: 250px;
    height: 56px;
    color: $grey-500;

    .input_icon {
      color: $grey-500;
    }
    .imbaded_input {
      outline: none;
      font-size: $font_size_lg + 0.1rem;
      color: $grey-500;
      font-weight: bold;
      width: 100px;
      height: 40px;
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $grey-300;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $grey-300;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $grey-300;
      }
    }
    .imbaded_button {
      background: #008060;
      border-radius: 5px;
      width: 93px;
      height: 44px;
      padding: 8px 24px;
      font-size: 14px;
      color: white;
      font-weight: bold;

      &:hover {
        background: $green-700;
      }
    }
  }
}
.th_icon {
  .icon {
    position: absolute;
  }
}
.countries {
  .flag {
    width: 30px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .country {
    font-size: $font_size_lg;
    font-weight: 600;
    color: $grey-500;
  }
}
.clickable {
  cursor: pointer;
  &:hover {
    background-color: $grey-50;
  }
}
.shipping_detail_wrapper {
  font-size: $font_size_lg;

  .shipping_table {
    width: 432px;
    th:first-child {
      border-radius: 10px 0 0 0;
    }
    th:last-child {
      border-radius: 0 10px 0 0;
    }
  }
}
.category_collection_wrapper {
  display: flex;
  gap: 24px;
  .sort_wrapper {
    width: 100% !important;
  }
}

@media screen and (max-width: 420px) {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
}
.checkmark2 {
  width: 16px;
  height: 16px;
  background: white;
  border: 1px solid $grey-400;
  border-radius: 2px;
  position: absolute;
  top: 16px;
  left: 11px;
}
</style>
