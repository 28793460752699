<template>
  <div class="fake_select_wrapper d-flex align-center">
    <span
      v-if="icon"
      class="material-icons input_icon"
      @click="(showSortBy = !showSortBy), (sort_by_arrow = !sort_by_arrow)"
    >
      {{ icon }}
    </span>
    <div class="fake_select">
      <input
        v-if="sortBy"
        type="text"
        class="text_input"
        id="sortBy"
        name="sortBy"
        :value="sortBy"
        @click="(showSortBy = !showSortBy), (sort_by_arrow = !sort_by_arrow)"
        readonly
        :disabled="disabled"
      />
      <input
        v-else-if="sortByObject"
        type="text"
        class="text_input"
        id="sortByObject"
        name="sortByObject"
        :value="sortByObject.title"
        @click="(showSortBy = !showSortBy), (sort_by_arrow = !sort_by_arrow)"
        readonly
        :disabled="disabled"
      />
      <div v-if="showSortBy && sortBy" class="drop_options">
        <div v-for="(sortKey, index) in sortList" :key="index">
          <div
            class="drop_option d-flex align-center"
            :class="sortBy === sortKey ? 'drop_option_active' : ''"
            @click="SortBy(sortKey), (sortBy = sortKey)"
          >
            {{ sortKey }}
            <span class="material-icons ml-auto" v-if="sortBy === sortKey">
              check
            </span>
          </div>
        </div>
      </div>
      <div v-else-if="showSortBy && sortByObject" class="drop_options">
        <div v-for="(sortKey, index) in sortList" :key="index">
          <div
            class="drop_option d-flex align-center"
            :class="sortByObject === sortKey ? 'drop_option_active' : ''"
            @click="SortByObject(sortKey), (sortByObject = sortKey)"
          >
            {{ sortKey.title }}
            <span
              class="material-icons ml-auto"
              v-if="sortByObject === sortKey"
            >
              check
            </span>
          </div>
        </div>
      </div>
    </div>

    <span
      class="material-icons icon"
      @click="(showSortBy = !showSortBy), (sort_by_arrow = !sort_by_arrow)"
      :class="sort_by_arrow ? 'arrow_active' : ''"
    >
      expand_more
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    sortList: { type: Array, required: true },
    icon: { type: String, required: false },
    disabled: { type: Boolean, default: false, required: false },
  },
  data() {
    return {
      sortBy: "",
      sortByObject: {},
      showSortBy: false,
      sort_by_arrow: false,
    };
  },
  created() {
    if (typeof this.sortList[0] === "object") {
      this.sortBy = null;
      this.sortByObject = this.sortList.find(
        (sort) => sort.id === (this.listOfProductsPagination.category || "all")
      );
    } else {
      this.sortByObject = null;
      this.sortBy = this.sortList[0];
    }
  },
  computed: {
    ...mapGetters({
      listOfProductsPagination: "retailer/listOfProductsPagination",
    }),
  },
  methods: {
    SortBy(value) {
      if (value == this.sortBy) return;
      this.$emit("sortby", {
        sort_by: value,
      });
      this.showSortBy = !this.showSortBy;
      this.sort_by_arrow = !this.sort_by_arrow;
    },
    SortByObject(value) {
      if (value == this.sortByObject) return;
      this.$emit("sortbyobject", {
        sort_by_object: value,
      });
      this.showSortBy = !this.showSortBy;
      this.sort_by_arrow = !this.sort_by_arrow;
    },
  },
};
</script>

<style lang="scss" scoped>
#sortBy {
  width: 362px;
}
.arrow_active {
  transform: rotateZ(180deg);
  transition: 0.2s;
}
</style>
